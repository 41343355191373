import React, { useEffect } from "react";

import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { generateBrowserId } from "./lib/misc";
import { appVersion } from "./config";

export default function RootLayout() {
  useEffect(() => {
    console.info("appVersion", appVersion);
    
    const browser_id = localStorage.getItem("browser_id");
    if (!browser_id) {
      const randomHash = generateBrowserId();
      localStorage.setItem("browser_id", randomHash);
    }
  }, []);
  return (
    <section className="main-wrapper">
      <Outlet />
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "green",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "white",
            },
          },
        }}
      />
    </section>
  );
}
