import React, { useState, useEffect } from "react";
import Rating from "@mui/material/Rating";

import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "../config";
import { getCookie } from "../lib/misc";

const RatingComponent = ({ defaultRating = 0, pointId }) => {
  const [stars, setStars] = useState(0);

  useEffect(() => {
    setStars(defaultRating);
  }, [pointId]);

  const handleRatingChange = (event, newValue) => {
    setStars(newValue);

    const browser_id = localStorage.getItem("browser_id");

    const options = {
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
    };

    axios
      .post(
        `${baseUrl}/api/ratings/`,
        {
          browser_id: browser_id,
          rating: newValue,
          point_id: pointId,
        },
        options
      )
      .then((resp) => {
        if (resp?.data?.success === true) {
          toast.success(`Спасибо! Ваша оценка учтена`, {
            duration: 3000,
          });
        } else {
          toast.error(`Возникла ошибка`);
        }
      })
      .catch((err) => {
        toast.error(`Возникла ошибка: ${JSON.stringify(err)}`, {
          duration: 3000,
        });
      });
  };

  return (
    <div>
      <Rating
        name="simple-controlled"
        value={stars}
        onChange={handleRatingChange}
        precision={0.5}
        max={5}
      />
    </div>
  );
};

export default RatingComponent;
