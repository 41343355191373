import React from "react";

import { is_url, is_website_without_schema } from "../lib/misc";

import CategoryIcon from "./ui/CategoryIcon";

import RatingComponent from "./Rating";

import "./ShowItem.css";
import ShareComponent from "./Share";

function ShowItem(props) {
  const { showInfoItem, onlyMobile, onlyDesktop, buttonTitle, onButtonClick } =
    props;

  if (!showInfoItem?.name) return "";

  return (
    <div
      className={
        "show-item " +
        (onlyMobile ? "only-mobile " : "") +
        (onlyDesktop ? "only-desktop" : "")
      }
    >
      <h2 className="is-size-5">
        <span className="mr-2 icon-wrapper">
          {CategoryIcon(showInfoItem.categoryId)}
        </span>
        <span>{showInfoItem.combinedCategory}</span>
      </h2>

      <p className="is-italic is-size-5">{showInfoItem.name}</p>
      <p className="mb-1">{showInfoItem.address}</p>
      {showInfoItem.telephone &&
        showInfoItem.telephone !== "no phone" &&
        showInfoItem.telephone !== "no_phone" && (
          <p className="mb-1">
            <a href={"tel:" + showInfoItem.telephone}>
              {showInfoItem.telephone}
            </a>
          </p>
        )}

      {showInfoItem.website === "no_site" ? (
        ""
      ) : is_website_without_schema(showInfoItem.website) ? (
        <p className="mb-1">
          <a target="_blank" href={`https://${showInfoItem.website}`}>
            {showInfoItem.website}
          </a>
        </p>
      ) : is_url(showInfoItem.website) ? (
        <p className="mb-1">
          <a target="_blank" href={showInfoItem.website}>
            {showInfoItem.website}
          </a>
        </p>
      ) : showInfoItem.website === "no_site" ? (
        ""
      ) : (
        <p className="mb-1">{showInfoItem.website}</p>
      )}

      <div
        className="close-dark"
        onClick={() => props.setShowInfoItem({})}
      ></div>

      <ShareComponent />

      <p className="is-size-7 mb-1">
        {showInfoItem.comment === "no_comment" ? "" : showInfoItem.comment}
      </p>

      {showInfoItem.languages?.length > 0 && (
        <p className="is-size-7 mb-1">
          Языки: {showInfoItem.languages.join(", ")}
        </p>
      )}

      {showInfoItem.price && (
        <p className="is-size-7 mb-1">Цена: {showInfoItem.price}</p>
      )}

      <RatingComponent
        pointId={showInfoItem.id}
        defaultRating={showInfoItem.rating}
      />

      {buttonTitle && showInfoItem?.map_x ? (
        <div className="mb-4 mt-4">
          <a href="" className="list-mode-toggle" onClick={onButtonClick}>
            {buttonTitle}
          </a>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ShowItem;
