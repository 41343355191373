import React from "react";

function NotFound() {
  return (
    <main className="">
      <p>Страница не найдена</p>
    </main>
  );
}

export default NotFound;
