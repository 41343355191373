import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { MultiSelect } from "react-multi-select-component";

import _ from "lodash";

import axios from "axios";

import { useSearchParams, useNavigate } from "react-router-dom";

import MainLogo from "../components/ui/MainLogo";
import AddObjectButton from "../components/AddObject/AddObjectButton";
import AddObjectModal from "../components/AddObject/AddObjectModal";

import ListItems from "../components/ListItems";
import ShowItem from "../components/ShowItem";

import { ReactComponent as BurgerOpenIcon } from "../assets/icons/burger-open.svg";
import { ReactComponent as BurgerCloseIcon } from "../assets/icons/burger-close.svg";

import { eventBus } from "../lib/eventBus";

import { defaultLocation, defaultMapState } from "../config";
import MapComp from "../components/Mapcomp";

import "./MapPage.css";

export const baseUrl = process.env.REACT_APP_BACKEND_API_URL;

export default function MapPage() {
  const navigate = useNavigate();
  const [selectedPointLang, setSelectedPointLang] = useState([]);
  const [languages, setLanguages] = useState([]);

  let [searchParams, setSearchParams] = useSearchParams();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState(null);

  const country = searchParams.get("country") || defaultLocation.country;
  const city = searchParams.get("city") || defaultLocation.city;
  const pointId = searchParams.get("point");

  const changeURL = (pointId = "") => {
    navigate(`/map?country=${country}&city=${city}&point=${pointId}`);
  };

  const [menuMapItems, setMenuMapItems] = useState([]);
  const [menuMapFilteredItems, setMenuMapFilteredItems] = useState([]);
  const [shownMapItems, setShownMapItems] = useState([]);
  const [shownFilteredMapItems, setShownFilteredMapItems] = useState([]);
  const [showInfoItem, setShowInfoItem] = useState({});
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  const [currentMapData, setcurrentMapData] = useState(defaultMapState);

  const selectPlace = (item) => {
    changeURL(item?.id);
    setShowInfoItem(item);

    setcurrentMapData({
      center: [item?.map_x, item?.map_y],
    });
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (!pointId) return;

    if (!shownMapItems.length) return;
    const itemFromUrl = shownMapItems.find(
      (item) => item.id === parseInt(pointId)
    );
    if (!itemFromUrl) return;
    clickPlace(itemFromUrl);
  }, [shownMapItems]);

  useEffect(() => {
    if (selectedPointLang.length === 0) {
      setShownFilteredMapItems(shownMapItems);
      return;
    }

    if (selectedPointLang.length === languages.length) {
      setShownFilteredMapItems(shownMapItems);
      return;
    }

    const selLang = selectedPointLang.map((item) => item.label);

    const filtered = shownMapItems.filter((item) => {
      return item.languages.some((item) => selLang.includes(item));
    });

    setShownFilteredMapItems(filtered);
  }, [shownMapItems, selectedPointLang]);

  useEffect(() => {
    if (menuMapItems.length === 0) return;

    if (languages.length === selectedPointLang.length) {
      setMenuMapFilteredItems(menuMapItems);
      return;
    }

    if (selectedPointLang.length === 0) {
      setMenuMapFilteredItems(menuMapItems);
      return;
    }

    const selLang = selectedPointLang.map((item) => item.label);

    const _menuMapItems = _.cloneDeep(menuMapItems);

    _menuMapItems.map((item) => {
      item.sub_category.map((item1) => {
        const filtered1 = item1.point_objects.filter((item) => {
          return item.languages.some((item) => selLang.includes(item));
        });
        item1.point_objects = filtered1;
      });
    });

    setMenuMapFilteredItems(_menuMapItems);
  }, [menuMapItems, selectedPointLang]);

  const detectLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setcurrentMapData({
            center: [position.coords.latitude, position.coords.longitude],
            zoom: 16,
          });
        },
        function (error) {
          if (error.code === 1) {
            toast.loading(
              `Вы запретили определение геолокации, карта наведена на город по умолчанию`,
              {
                duration: 3000,
              }
            );
          }
          console.error("Error Code = " + error.code + " - " + error.message);
        },
        {
          enableHighAccuracy: true, // Whether to improve accuracy at the cost of response time and power consumption
          timeout: 10000, // Maximum time in milliseconds that the device is allowed to take in order to return a position
          maximumAge: 0, // Maximum age in milliseconds of a possible cached position that is acceptable to return
        }
      );
    } else {
      console.info("Geolocation is not supported by this browser.");
    }
  };

  const getShowModalFromUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let addPointModalParam = queryParams.get("add-point-modal");

    if (!addPointModalParam) {
      addPointModalParam = queryParams.get("add_point_modal");
    }

    if (addPointModalParam === "1") {
      eventBus.dispatch("showModalAddObject", {});
    }
  };

  const onLoad = () => {
    getShowModalFromUrl();
    getCountries();
    getMenuItems();
    getLanguages();
    setTimeout(() => {
      detectLocation();
    }, 1000);
  };

  const getMenuItems = () => {
    let params = "";
    if (country && city) {
      params = `/api/categories_tree/?country=${country}&city=${city}`;
    } else if (city) {
      params = `/api/categories_tree/?city=${city}`;
    }

    axios.get(`${baseUrl}${params}`).then((res) => {
      const menuItems = res.data;
      setMenuItems(menuItems);
    });
  };

  const getLanguages = () => {
    axios
      .get(`${baseUrl}/api/languages/`)
      .then((data) => {
        const langs = [];
        data.data.map((item) => {
          langs.push({ label: item.name, value: item.code });
        });

        setLanguages(langs);
      })
      .catch((error) => {
        console.info("Error", error);
      });
  };

  const getCountries = () => {
    axios
      .get(`${baseUrl}/api/countries/`)
      .then((data) => {
        setCountries(data.data);

        const countryData = data.data.find((item) => item.name === country);

        if (countryData?.map_x) {
          setcurrentMapData({
            center: [countryData?.map_x, countryData?.map_y],
            zoom: 16,
          });
        }
      })
      .catch((error) => {
        console.info("Error", error);
      });
  };

  const calcCityId = () => {
    cities.map((item) => {
      if (item.name === city) {
        setCityId(item.id);
      }
    });
  };

  const getCities = () => {
    if (!countries.length) return;

    axios
      .get(`${baseUrl}/api/cities/`)
      .then((data) => {
        setCities(data.data);
      })
      .catch((error) => {
        console.info("Error", error);
      });
  };

  useEffect(() => {
    if (!countries.length || !cities.length) return;

    cities.map((item) => {
      item.countryData = countries.find((item2) => item2.id === item.country);
      item.countryName = countries.find(
        (item2) => item2.id === item.country
      ).name;
    });

    calcCityId();
  }, [cities]);

  useEffect(() => {
    getCities();
  }, [countries]);

  const toggleMenu = () => {
    setIsMobileMenuOpened(!isMobileMenuOpened);
  };

  const clickSubMenuItem = (item) => {
    setShowInfoItem({});

    const firstItem = item.point_objects[0];

    if (!firstItem) return;
  };

  const clickPlace = (
    item,
    doNotSetMapData = false,
    hideMobileMenu = false
  ) => {
    setShowInfoItem(item);
    changeURL(item?.id);

    if (hideMobileMenu) {
      setIsMobileMenuOpened(false);
    }

    if (doNotSetMapData) {
      return;
    }

    if (!item?.map_x || !item?.map_y) {
      return;
    }

    setcurrentMapData({
      center: [item?.map_x, item?.map_y],
      zoom: 16,
    });
  };

  const clickPoint = (item) => {
    setcurrentMapData({
      center: [item?.map_x, item?.map_y],
      zoom: 16,
    });
  };

  const handleChangeFilterLanguage = (e) => {
    setSelectedPointLang(e);
  };

  const setMenuItems = (menuItems) => {
    const mapItemsFlattened = [];

    /* Подготавливаем массив меню для рендеринга */
    const _menuItems = menuItems.map((item) => {
      const category = item.name;
      const categoryId = item.id;
      item.sub_category.map((item2) => {
        const subCategory = item2.name;
        item2.categoryId = categoryId;

        item2.point_objects.map((item3) => {
          item3.subCategory = subCategory;
          item3.category = category;
          item3.categoryId = categoryId;
          item3.combinedCategory = `${category} / ${subCategory}`;
          mapItemsFlattened.push({ ...item3 });
          return item3;
        });
        return item2;
      });
      return item;
    });

    setMenuMapItems(_menuItems);
    setShownMapItems(mapItemsFlattened);
  };

  return (
    <>
      <div className="top-menu position-absolute bg-blur pr-5 pl-5">
        <div className="d-flex">
          <MainLogo
            className=""
            onClick={() => navigate("/")}
            title="Перейти на главную"
          />
        </div>

        <div className="select-languages select-languages-map-page">
          <MultiSelect
            options={languages}
            value={selectedPointLang}
            onChange={handleChangeFilterLanguage}
            hasSelectAll={false}
            disableSearch={true}
            ClearIcon={<></>}
            ClearSelectedIcon={<></>}
            overrideStrings={{
              selectSomeItems: "Языки",
              allItemsAreSelected: "Все языки",
              selectAll: "Выбрать все",
            }}
          />
        </div>

        <p></p>

        {isMobileMenuOpened ? (
          <BurgerCloseIcon
            onClick={toggleMenu}
            className="mobile-menu-toggle"
          />
        ) : (
          <BurgerOpenIcon onClick={toggleMenu} className="mobile-menu-toggle" />
        )}
      </div>
      <div className="map-wrapper">
        <MapComp
          currentMapData={currentMapData}
          shownFilteredMapItems={shownFilteredMapItems}
          clickPlace={clickPlace}
          setShowInfoItem={setShowInfoItem}
          showInfoItem={showInfoItem}
          selectPlace={selectPlace}
        />

        <ShowItem
          showInfoItem={showInfoItem}
          defaultContent="Выберите обьект на карте"
          onlyMobile
          setShowInfoItem={setShowInfoItem}
        />

        <div
          className={
            "right-pane bg-blur " + (isMobileMenuOpened ? "menu-is-opened" : "")
          }
        >
          <div className="d-flex justify-between mb-4">
            <AddObjectButton
              triggerButton={
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="list-mode-toggle mb-4 hide-on-desktop"
                >
                  Добавить объект
                </a>
              }
            />
          </div>

          <ListItems
            menuMapFilteredItems={menuMapFilteredItems}
            clickPlace={clickPlace}
            clickSubMenuItem={clickSubMenuItem}
            clickPoint={clickPoint}
            className="mb-2"
          />

          <ShowItem
            showInfoItem={showInfoItem}
            defaultContent="Выберите обьект на карте"
            onlyDesktop
            setShowInfoItem={setShowInfoItem}
          />
        </div>
      </div>

      <AddObjectButton />
      <AddObjectModal languages={languages} cityId={cityId} />

      <div className="copyright-openstreetmap" href="">
        OpenStreetMap
      </div>
    </>
  );
}
