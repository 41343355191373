import React from 'react';
import { ReactComponent as GovernmentIcon } from "../../assets/icons/government.svg";
import { ReactComponent as BeautyIcon } from "../../assets/icons/map_beauty-salon.svg";
import { ReactComponent as MedicineIcon } from "../../assets/icons/healthicons_medicines-outline.svg";
import { ReactComponent as EducationIcon } from "../../assets/icons/cil_education.svg";
import { ReactComponent as RepairIcon } from "../../assets/icons/repair.svg";
import { ReactComponent as ServicesIcon } from "../../assets/icons/services.svg";
import { ReactComponent as CultureIcon } from "../../assets/icons/culture.svg";
import { ReactComponent as SportsIcon } from "../../assets/icons/sports.svg";
import { ReactComponent as PetsIcon } from "../../assets/icons/pets.svg";

const icons = {
  1: <BeautyIcon />,
  3: <GovernmentIcon />,
  4: <MedicineIcon />,
  5: <EducationIcon />,
  6: <MedicineIcon />,
  13: <RepairIcon />,
  10: <ServicesIcon />,
  14: <SportsIcon />,
  7: <CultureIcon />,
  15: <PetsIcon />,
};

const CategoryIcon = (id) => {
  if (id in icons) return icons[id];
  return icons[3];
};

export default CategoryIcon;
