import React from "react";

import './AddObjectButton.css';
import { eventBus } from "../../lib/eventBus";

function AddObjectButton(props) {
    const { triggerButton } = props;

    const showModal = () => {
        eventBus.dispatch("showModalAddObject", {});
    }

    return (
        <div onClick={showModal}>
            {triggerButton ? triggerButton : (
                <div className="add-object-button hide-on-mobile cursor-pointer">
                    Добавить точку
                </div>
            )}
        </div>
    )
}

export default AddObjectButton;
