export const appVersion = "1.0.3";

export const citiesCenters = {
  warsaw: [52.22843, 21.00938],
  warsaw2: [52.2352940910932, 21.037043533039498],
};

export const baseUrl = process.env.REACT_APP_BACKEND_API_URL;

export const defaultLocation = {
  country: "Польша",
  city: "Варшава",
};

export const defaultMapState = {
  center: citiesCenters.warsaw2,
  zoom: 16,
};
