import React from 'react';
import { HashRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";

import './assets/css/main.css';
import './assets/css/helpers.css';
import './assets/leaflet/leaflet.css';

function App() {
  return (
    <HashRouter>
      <AppRoutes />
    </HashRouter>
  );
}

export default App;
