import React from "react";

import { Routes, Route } from "react-router-dom";

import MapPage from "./screens/MapPage";
import GoogleMapPage from "./screens/GoogleMapPage";
import NotFound from "./screens/NotFound";

import RootLayout from "./RootLayout";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route index={true} element={<MapPage />} />
        <Route path="map" element={<MapPage />} />
        <Route path="gmap" element={<GoogleMapPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
