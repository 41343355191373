import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as TelegramIcon } from '../assets/icons/telegram_icon_green.svg';
import MainLogo from "../components/ui/MainLogo";
import './GoogleMapPage.css'

export default function GoogleMapPage() {
  const navigate = useNavigate();

  const handleChangeCity = (e) => {
    const cityName = e.target.value
    if (cityName === 'Тбилиси') {
      navigate(`/gmap`)
      return;
    }

    if (cityName === 'Варшава') {
      navigate(`/map?country=Польша&city=Варшава`, { replace: true })
      return;
    }
  }

  return (
    <div className="wh-100 d-flex flex-column">

      <div className="top-menu bg-blur pr-5 pl-5 justify-between">

        <div className="d-flex">
          <MainLogo
            className="mr-5"
            onClick={() => navigate('/')}
            title="Перейти на главную"
          />
          <a
            className="map-telegram-link"
            target="_blank"
            href="https://t.me/expatmap"
            title="Перейти на телеграм-канал"
          >
            <TelegramIcon className="map-telegram-icon" />
          </a>
        </div>

        <div className="select select-country" onChange={handleChangeCity}>
          <select defaultValue="none">
            <option value="none" disabled>Выбор города</option>
            <option value="Варшава">Варшава</option>
            <option value="Тбилиси">Тбилиси</option>
          </select>
        </div>
        <p></p>
      </div>

      <div className="flex-1">
        <iframe src="https://www.google.com/maps/d/embed?mid=1v4ib_C0SG3r0nhGnmFzUwEF2Vsqpj4o&ehbc=2E312F" width="100%" height="100%"></iframe>
        {/* <iframe src="https://www.google.com/maps/d/embed?mid=1OLyeDSjhi0mCgmKG9B2-AD6X9TltnJw&ehbc=2E312F" width="100%" height="100%"></iframe>*/}
      </div>
    </div>
  );
}
