import React, { useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-hot-toast";

const ShareComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyUrl = () => {
    handleClose();
    const fullURL = window.location.href;
    navigator.clipboard
      .writeText(fullURL)
      .then(() => {
        toast.success(`Ссылка на точку скопирована в буфер обмена`, {
          duration: 1500,
        });
      })
      .catch((error) => {
        toast.error(`Не удалось скопировать ссылку`, {
          duration: 1500,
        });
      });
  };

  return (
    <>
      <span
        className="share"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      ></span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleCopyUrl}>Скопировать ссылку</MenuItem>
      </Menu>
    </>
  );
};

export default ShareComponent;
