import React from "react";
import { MapContainer, TileLayer, useMap, Marker } from "react-leaflet";
import L from "leaflet";

function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

export default function MapComp({
  showInfoItem,
  currentMapData,
  shownFilteredMapItems,
  selectPlace,
}) {
  const defaultTooltipIcon = {
    iconUrl: "static/leaflet/marker-icon.png",
    shadowUrl: "static/leaflet/marker-shadow.png",

    iconSize: [25, 41], // size of the icon
    shadowSize: [41, 41], // size of the shadow
    iconAnchor: [10, 41], // point of the icon which will correspond to marker's location
    shadowAnchor: [10, 41], // the same for the shadow
    popupAnchor: [10, 51], // point from which the popup should open relative to the iconAnchor
  };

  const tooltipIcon = L.icon({
    ...defaultTooltipIcon,
  });

  const selectedTooltipIcon = L.icon({
    ...defaultTooltipIcon,
    iconUrl: "static/leaflet/marker-icon-selected.png",
  });

  return (
    <MapContainer
      classsName="map"
      center={currentMapData.center}
      zoom={currentMapData.zoom}
    >
      <ChangeView center={currentMapData.center} zoom={currentMapData.zoom} />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://tile.osm.ch/switzerland/{z}/{x}/{y}.png"
      />
      {shownFilteredMapItems &&
        shownFilteredMapItems
          .filter((item) => item.map_x)
          .map((item, i) => {
            return (
              <Marker
                key={i}
                position={[item?.map_x, item?.map_y]}
                icon={
                  item.id === showInfoItem.id
                    ? selectedTooltipIcon
                    : tooltipIcon
                }
                eventHandlers={{
                  click: (e) => {
                    //setShowInfoItem({});
                    //clickPlace(item, true);
                    selectPlace(item);
                  },
                }}
              ></Marker>
            );
          })}
    </MapContainer>
  );
}
