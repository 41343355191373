import React, { useState, useEffect } from "react";

import CategoryIcon from "./ui/CategoryIcon";

import { ReactComponent as ArrowDownIcon } from "../assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "../assets/icons/arrow-up.svg";
import { ReactComponent as DashIcon } from "../assets/icons/dash.svg";

import "./ListItems.css";

function ListItems({ menuMapFilteredItems, clickSubMenuItem, clickPlace }) {
  const [toggleItems, setToggleItems] = useState({});

  useEffect(() => {
    initToggleConfig();
  }, [menuMapFilteredItems]);

  const initToggleConfig = () => {
    const toggles = {};

    menuMapFilteredItems.map((item) => {
      const subToggles = {};
      item.sub_category.map((item2) => {
        subToggles[item2.id] = { open: false };
      });
      toggles[item.id] = { open: false, ...subToggles };
    });
    setToggleItems(toggles);
  };

  const toggleShowFirst = (id) => {
    const isOpen = toggleItems[id]["open"];

    Object.keys(toggleItems).map((id) => {
      toggleItems[id].open = false;
    });

    setToggleItems({
      ...toggleItems,
      [id]: {
        ...toggleItems[id],
        open: !isOpen,
      },
    });
  };

  const clickSubItem = (firstItem, secondItem) => {
    clickSubMenuItem(secondItem);

    const _toggleItems = toggleItems;

    const isOpen = _toggleItems[firstItem.id][secondItem.id].open;

    Object.entries(_toggleItems[firstItem.id]).map((item) => {
      const key = item[0];
      if (key === "open") return;
      _toggleItems[firstItem.id][key] = { open: false };
    });

    _toggleItems[firstItem.id][secondItem.id].open = !isOpen;
    setToggleItems(_toggleItems);
  };

  return (
    <ul className="list-items">
      {menuMapFilteredItems.map((item, i) => {
        if (!item?.sub_category) return;

        return (
          <li
            className={
              "top-level-item " + (toggleItems[item.id]?.open ? "open" : "")
            }
            key={i}
          >
            <p
              className="cursor-pointer mb-2"
              onClick={() => toggleShowFirst(item.id)}
            >
              <span className="mr-2">{CategoryIcon(item.id)}</span>
              <span className="mr-2">{item.name}</span>
              {toggleItems[item.id]?.open ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </p>

            {item?.sub_category?.length ? (
              <ul className="second-level-items">
                {item.sub_category.map((item2, j) => {
                  return (
                    <li
                      className={
                        "second-level-item mb-2 " +
                        (toggleItems?.[item.id]?.[item2.id]?.open
                          ? "open-third"
                          : "")
                      }
                      key={j}
                    >
                      <p
                        className="cursor-pointer sub-level-title"
                        onClick={() => clickSubItem(item, item2)}
                      >
                        <span className="mr-2">{item2.name}</span>
                        <span>
                          {toggleItems?.[item.id]?.[item2.id]?.open ? (
                            <ArrowUpIcon />
                          ) : (
                            <ArrowDownIcon />
                          )}
                        </span>
                      </p>

                      <ul className="third-level-items">
                        {item2.point_objects.map((item3, k) => {
                          return (
                            <li key={k}>
                              <span
                                className="sub-level-title"
                                onClick={() => clickPlace(item3, false, true)}
                              >
                                <DashIcon className="mr-2" />
                                {item3.name}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </li>
        );
      })}
    </ul>
  );
}

export default ListItems;
