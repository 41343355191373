import React from 'react';
import { useState, useEffect, useMemo } from "react";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { MapContainer, TileLayer } from "react-leaflet";
import MarkerIcon from "../assets/leaflet/images/marker-icon.png";

import Fade from "@mui/material/Fade";

import toast from "react-hot-toast";

import { defaultMapState } from "../config";

import "./AddressPicker.css";

const center = defaultMapState.center;
const zoom = defaultMapState.zoom;
const MIN_REQUEST_INTERVAL = 1000;

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function getAddressFromCoordinates(lat, lon) {
  const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;

  return fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => {
      if (data && data.address) {
        // Extract the address information from the response
        const address = data.address;

        const addressComponents = [
          address.country,
          address.city,
          address.village,
          address.municipality,
          address.road,
          address.highway,
          address.state,
          address.quarter,
          address.neighbourhood,
          address.house_number,
        ];

        const formattedAddress = addressComponents
          .filter((component) => component !== undefined)
          .join(", ");

        return formattedAddress;
      } else {
        toast.error(`При загрузке адреса возникла ошибка`, {
          duration: 2400,
        });

        throw new Error("Address not found");
      }
    });
}

function AddressPicker({ setPickedAddress }) {
  const [map, setMap] = useState(null);
  const [markerIsShown, setMarkerIsShown] = useState(true);
  const [showInitialHint, setShowInitialHint] = useState(true);
  const [address, setAddress] = useState("");

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const useThisAddress = () => {
    setMarkerIsShown(false);
    const coords = map.getCenter();
    setPickedAddress({
      coords: coords,
      address: address,
    });
  };

  const handleMoveStart = () => {
    setShowInitialHint(false);
    setMarkerIsShown(false);
    setAddress("");
  };

  const getAddr = debounce(() => {
    const coords = map.getCenter();

    getAddressFromCoordinates(coords?.lat, coords?.lng).then((addr) => {
      setAddress(addr);
    });
  }, MIN_REQUEST_INTERVAL);

  const handleMoveEnd = () => {
    setMarkerIsShown(true);
    getAddr();
  };

  useEffect(() => {
    if (map) {
      map.on("movestart", handleMoveStart);
      map.on("moveend", handleMoveEnd);
    }

    return () => {
      if (map) {
        map.off("movestart", handleMoveStart);
        map.off("moveend", handleMoveEnd);
      }
    };
  }, [map]);

  const displayMap = useMemo(
    () => (
      <MapContainer center={center} zoom={zoom} ref={setMap}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    ),
    []
  );

  return (
    <div className="h-100">
      {displayMap}

      <HtmlTooltip
        open={markerIsShown}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        placement="top"
        arrow
        title={
          <div className="p-3 tooltip-html">
            {address ? (
              <>
                <p className="use-this-point-address mb-4">{address}</p>
                <span
                  onClick={useThisAddress}
                  className="use-this-point cursor-pointer"
                >
                  Использовать этот адрес &gt;
                </span>
              </>
            ) : (
              <p className="use-this-point-address mb-4">
                {showInitialHint
                  ? "Начните двигать и приближать карту"
                  : "Загрузка..."}
              </p>
            )}

            <div className="mt-3 hint-text">
              Чтобы извлечь адрес точнее, приблизьте маркер максимально близко к
              нужному зданию
            </div>
          </div>
        }
      >
        <img src={MarkerIcon} className="marker-center-icon" />
      </HtmlTooltip>
    </div>
  );
}

export default AddressPicker;
