import React from "react";

import "./MainLogo.css";

function MainLogo(props) {
  return (
    <div
      title={props?.title}
      onClick={props?.onClick}
      className={
        "cursor-pointer logo " + (props.className ? props.className : "")
      }
    >
      <div className="logo-expat"></div>
    </div>
  );
}

export default MainLogo;
