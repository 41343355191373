import React, { useState, useEffect } from "react";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import toast from "react-hot-toast";

import { ReactComponent as BurgerCloseIcon } from "../../assets/icons/burger-close.svg";
import { eventBus } from "../../lib/eventBus";
import { getCookie } from "../../lib/misc";

import { baseUrl, defaultMapState } from "../../config";
import AddressPicker from "../AddressPicker";

import "./AddObjectModal.css";

function AddObjectModal({ cityId = null, languages = [] }) {
  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalSelectCoordShown, setIsModalSelectCoordShown] = useState(false);
  const [selectedPointLang, setSelectedPointLang] = useState([]);
  const [_languages, _setLanguages] = useState(languages);

  const getLanguages = () => {
    axios
      .get(`${baseUrl}/api/languages/`)
      .then((data) => {
        const langs = [];
        data.data.map((item) => {
          langs.push({ label: item.name, value: item.code });
        });

        _setLanguages(langs);
      })
      .catch((error) => {
        console.info("Error", error);
      });
  };

  const handleChangeFilterLanguage = (e) => {
    setformValue({
      ...formValue,
      languages_codes: e.map((item) => item.value).join(","),
    });

    setSelectedPointLang(e);
  };

  const [letterCount, setLetterCount] = useState(0);

  const defaultState = {
    name: "",
    address: "",
    telephone: "",
    website: "",
    comment: "",
    map_x: "",
    map_y: "",
    storage_version: "1.0.1",
    city: "",
    languages_codes: "",
    is_online_placement: false,
  };

  const [formValue, setformValue] = useState(defaultState);

  const maxLettersCount = 500;

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setformValue({ ...formValue, [name]: checked });
    } else {
      if (name === "comment" && value.length > maxLettersCount) {
        return;
      }
      setformValue({ ...formValue, [name]: value });
    }

    localStorage.setItem("formValue", JSON.stringify(formValue));
  };

  useEffect(() => {
    if (!cityId) return;
    setformValue({
      ...formValue,
      city: cityId,
    });
  }, [cityId]);

  const onLoad = () => {
    if (!_languages?.length) {
      getLanguages();
    }
    eventBus.on("showModalAddObject", () => {
      showModal();
    });

    let lcstrFormValue = localStorage.getItem("formValue");
    if (lcstrFormValue) {
      setformValue({ ...formValue, ...JSON.parse(lcstrFormValue) });
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const sendObject = () => {
    const _formValue = {};

    Object.entries(formValue).map((item) => {
      if (item[1]) _formValue[item[0]] = item[1];
    });

    const options = {
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
    };

    axios
      .post(`${baseUrl}/api/add_objects_on_map/`, _formValue, options)
      .then((resp) => {
        if (resp?.data?.id) {
          localStorage.removeItem("formValue");
          setformValue(defaultState);

          toast(
            (t) => (
              <span>
                <p className="pr-4">
                  Спасибо, что поделились информацией! Объект появится на карте,
                  как только мы проверим данные
                </p>
                <div
                  className="close-dark"
                  onClick={() => toast.dismiss(t.id)}
                ></div>
              </span>
            ),
            {
              duration: 5000,
            }
          );
        } else {
          throw "7801";
        }
      })
      .catch((err) => {
        const _err = err?.response?.data
          ? err.response.data
          : err?.message
          ? err.message
          : err;
        toast.error(`Возникла ошибка: ${JSON.stringify(_err)}`, {
          duration: 3000,
        });
      });

    closeModal();
  };

  const showModal = () => {
    setIsModalShown(true);
  };

  const closeModal = () => {
    setIsModalShown(false);

    const urlParams = new URLSearchParams(window.location.search);

    const addPointModalParam = urlParams.get("add-point-modal");
    if (!addPointModalParam) return;

    setTimeout(() => {
      urlParams.delete("add-point-modal");

      // Get the current path without the query parameters
      const pathWithoutQuery = window.location.pathname;

      // Construct the new URL with the updated query parameters
      const newUrl = `${pathWithoutQuery}?${urlParams.toString()}`;
      // Use window.location.replace to update the URL
      window.location.replace(newUrl);
    }, 4000);
  };

  const updateLetterCounter = () => {
    setLetterCount(formValue.comment.length);
  };

  const setPickedAddress = ({ coords, address }) => {
    setIsModalSelectCoordShown(false);
    setformValue({
      ...formValue,
      address: address,
      map_x: coords?.lat,
      map_y: coords?.lng,
    });

    localStorage.setItem("formValue", JSON.stringify(formValue));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendObject();
  };

  return isModalShown ? (
    <div className="add-object-modal-wrapper bg-blur align-items-center justify-center d-flex">
      {!isModalSelectCoordShown && (
        <div className="modal-body flex-1 p-5 overflow-auto">
          <BurgerCloseIcon
            onClick={closeModal}
            className="close-modal cursor-pointer"
          />
          <div className="text-center">
            <h3 className="title is-4 mt-5">Добавить точку</h3>

            <form onSubmit={handleSubmit}>
              <p className="has-font-size-2">
                Название <span className="color-red">*</span>
              </p>
              <input
                required
                name="name"
                className="input is-small underline-input mb-4"
                value={formValue.name}
                onChange={handleChange}
              />

              <p className="has-font-size-2">Телефон</p>
              <input
                name="telephone"
                className="input is-small underline-input mb-4"
                value={formValue.telephone}
                onChange={handleChange}
              />

              <p className="has-font-size-2">Языки</p>
              <div className="select-languages select-languages-add-modal mb-3 position-relative">
                <MultiSelect
                  options={_languages}
                  value={selectedPointLang}
                  onChange={handleChangeFilterLanguage}
                  hasSelectAll={false}
                  disableSearch={true}
                  ClearIcon={<></>}
                  ClearSelectedIcon={<></>}
                  overrideStrings={{
                    selectSomeItems: "Выберите языки",
                    allItemsAreSelected: "Все языки",
                    selectAll: "Выбрать все",
                  }}
                />
                <input
                  name="languages"
                  value={
                    selectedPointLang.length > 0
                      ? JSON.stringify(selectedPointLang)
                      : ""
                  }
                  required
                  className="invisible-input"
                  onChange={() => {}}
                />
              </div>

              <p className="has-font-size-2">Ссылка</p>
              <input
                name="website"
                className="input is-small underline-input mb-4"
                value={formValue.website}
                onChange={handleChange}
              />

              <div className="mb-5 d-flex align-items-center justify-center">
                <label
                  htmlFor="is_online_placement"
                  className="has-font-size-2 mr-3"
                >
                  Онлайн
                </label>
                <input
                  name="is_online_placement"
                  id="is_online_placement"
                  className="is-medium"
                  type="checkbox"
                  value={formValue.is_online_placement}
                  onChange={handleChange}
                />
              </div>

              <p className="has-font-size-2">Адрес {formValue.is_online_placement === true && "(не обязательно)"}</p>
              <input
                required={formValue.is_online_placement === false}
                name="address"
                className="input is-small underline-input mb-2"
                value={formValue.address}
                onChange={handleChange}
              />
              <p className="has-font-size-2 mb-5">
                <span
                  className="border-bottom-dashed cursor-pointer select-on-map"
                  onClick={() => setIsModalSelectCoordShown(true)}
                >
                  Выбрать на карте 📍
                </span>
              </p>

              <p className="mb-3">Комментарий</p>

              <div className="position-relative">
                <textarea
                  name="comment"
                  className="textarea underline-input underline-textarea mb-4"
                  placeholder=""
                  value={formValue.comment}
                  onChange={(e) => {
                    updateLetterCounter();
                    handleChange(e);
                  }}
                ></textarea>
                <span className="letter-counter-label">{`${letterCount} / ${maxLettersCount}`}</span>
              </div>

              <div className="mb-5"></div>

              <div className="justify-between d-flex mb-5">
                <button className="button button-outlined" onClick={closeModal}>
                  Отмена
                </button>

                <button type="submit" className="button button-main">
                  Поделиться
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isModalSelectCoordShown && (
        <div className="modal-body flex-1 overflow-auto">
          <AddressPicker
            setPickedAddress={setPickedAddress}
            currentMapData={defaultMapState}
          />
          <p className="select-on-map-title">Выбор координаты</p>
          <BurgerCloseIcon
            onClick={() => setIsModalSelectCoordShown(false)}
            className="close-modal cursor-pointer"
          />
        </div>
      )}
    </div>
  ) : null;
}

export default AddObjectModal;
